<template>
  <div class="login h_100 flex flex-justify-center" :style="'background-image:url('+bgImgSrc+')'">
    <div class="inner_box">
      <div class="title t-center">蚂蚁助学商家端</div>
      <div class="desc t-center">选择蚂蚁助学，轻松管理分期账单</div>
      <el-form ref="form" :model="param" :rules="rules">
        <div style="margin: 40px 0;">
          <el-form-item prop="name">
            <el-input
              v-model="param.mobile"
              placeholder="账号"
              prefix-icon="el-icon-user"
              @keyup.enter.native="login"
            />
          </el-form-item>
          <el-form-item prop="name">
            <el-input
              type="password"
              prefix-icon="el-icon-lock"
              v-model="param.password"
              placeholder="密码"
              show-password
              @keyup.enter.native="login"
            />
          </el-form-item>
        </div>
        <div style="text-align:right;">
          <el-button type="primary" class="w_100"
            :loading="loginLoading"
            @click="login">登录</el-button>
          <el-button type="text" style="padding:0; margin-top:10px;" @click="gotoReset">重置密码</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { JSEncrypt } from "jsencrypt";
import { Login } from "@/api/login";
export default {
  name: "Login",
  data() {
    return {
      bgImgSrc: require('@/assets/img/login_bg.jpg'),
      param: {
        mobile: "",
        password: "",
      },
      rules: {
        mobile: [{ required: true, message: "请输入账号", trigger: blur }],
        password: [{ required: true, message: "请输入密码", trigger: blur }],
      },
      loginLoading: false,
    };
  },
  created() {},
  methods: {
    async login() {
      this.loginLoading = true;
      let pwd = this.passwordEncryption(this.param.password);
      let mobile = this.param.mobile.replace(/\s*/g,"")
      const params = {
        mobile: mobile,
        password: pwd,
      };
      const { code, data } = await Login(params);
      if (code == 200) {
        localStorage.setItem("__token__", data.token);
        localStorage.setItem("__managerType__", data.manager_type);
        localStorage.setItem("__auth__", JSON.stringify(data.auth_list));
        localStorage.setItem("__username__", data.username);

        localStorage.setItem('__manage_senior__', JSON.stringify(data.manage_senior))
        localStorage.setItem('__manage_entity__', JSON.stringify(data.manage_entity))
        localStorage.setItem('__manage_business__', JSON.stringify(data.manage_business))

        
        this.loginLoading = false;
        const toPath = decodeURIComponent(this.$route.query?.redirect || "/");
        this.$router.replace(toPath).then(() => {
          if (this.$route.name == "login") {
            location.reload();
            this.$router.replace("/");
          }
        });
      } else {
        this.loginLoading = false;
      }
    },
    passwordEncryption(passwordUser) {
      let publicKey =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC1qsjXu6Oblc7W6B/q0+NXUYJ3dN9X4BZ3xncrKJEfCBwS5IE3ZgABQe2MR0fagpfKahtUaeVjLRGJh6A23yyHewxI6kfuAc+/oTxZLJia6K/l46JbLwb96DatpTa9sAk+5MS6IZ94fbaQ8iv9rn+h3Ddo9MDoPW+/sVtWtv9lbwIDAQAB";
      let encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      let passwordEncryp = encryptor.encrypt(passwordUser);
      return passwordEncryp;
    },

    gotoReset(){
      // 增加一个重置密码页面（手机号+短信验证码）
      this.$router.replace("/passwordReset");
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  background-color: #f5f7f9;
  background-repeat: no-repeat;
  // background-size: 100%;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1000px;
  .inner_box {
    margin-left: 500px;
    margin-bottom: 80px;
    border-radius: 20px;
    padding: 40px 50px;
    width: 400px;
    background: rgba(255, 255, 255, 0.75);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.06);
    .title {
      color: #333;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 20px;
    }
    .desc {
      color: #999;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 30px;
    }
    /deep/ .el-form-item {
      // background-color: #283443;
      border: 1px solid #ccc;
      border-radius: 4px;
      .el-input {
        input {
          height: 20px;
          padding-right: 30px;
          font-size: 13px;
          background-color: transparent;
          border: none;
        }
      }
    }
  }
}
</style>
